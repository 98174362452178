a {
  cursor: pointer;
  display: inline;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  text-decoration-line: none !important;
  width: auto;
}

.no-arrow-number input::-webkit-outer-spin-button,
.no-arrow-number input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.no-arrow-number input[type='number'] {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}
